export const generalConfig = {
  hmr: false,
  // Estructura copiada de Funerama. Si algún día la necesitamos, hay que cambiar las rutas
  auth: {
    clientID:
      '807827417722-5acck2ti530nh7ejqms1bvoemcpfdj6m.apps.googleusercontent.com',
    scope: 'profile email openid https://www.googleapis.com/auth/calendar',
  },
  // refreshToken: '/auth/jwt/refresh/',
  // recoverPasword: '/auth/password/reset/',
  // imageGallery: '/image-gallery/',
  services: {
    auth: {
      apiKeyCalendar: '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',
      // Post
      saveUser: '/api/user/save',
      obtainAppToken: '/oauth/app_token',
      forgotPassword: '/api/user/forgotPassword',
      iniciarSesion: '/api/login',
      findByEmail: '/api/user/findByEmail',
      verificationToken: '/api/user/authenticationToken',
      changePassword: '/api/user/resetPassword',
      verifyAccount: '/api/user/validateEmail',
      forwardEmail: '/api/user/forwardEmail',
      validateEmail: '/api/user/validateEmail',
      verifyData: '/api/user/verifyData',
    },
    candidates: {
      // Get
      getListRecruiters: '/api/candidate/followersList',
      getRecruiter: '/api/candidate/recruiter-info/',
      getCandidates: '/api/candidate/',
      candidatoExiste: '/api/candidateExiste/',
      getLanguageLevels: '/api/candidate/languageLevels',
      getTechnologies: '/api/technology/',
      getEmailIsCreated: '/api/candidate/emailExist/',
      getDniExiste: '/api/dniExiste/',
      getPerfilesScrappeados: '/api/linkedin/listaPerfilesScrappeados',
      getInfoById: '/api/candidate/',
      getInfoCandidateByIdAndOportunity: '/api/candidate/candidateCard/',
      getFile: '/api/file/',
      getLinkedinExists: '/api/candidate/existsLinkedin/',
      getCandidateByVacant: '/api/candidate/card/',
      getUploadCandidateByLinkedinUrl: '/api/candidate/scraping/',
      getProfiles: '/api/candidate/profilesList',
      getRecomendList: '/api/candidate/bestScores/',
      getBusinessActivities: '/api/candidate/businessActivities',
      getLanguages: '/api/candidate/languages',
      getCandidatesByVacant: '/api/candidate/candidatesByVacant/',
      getExperienceScore: '/api/candidateScore/',
      getEducationScore: '/api/candidateScore/findEducationScore/',
      getExportCandidateToPdf: '/api/candidate/downloadpdf/',
      getCandidateByVacantByIds: '/api/candidate/candidateByVacantByIds/',
      getFollowers: '/api/candidate/getFollowers/',
      getActivateStageByCandidate:
        '/api/candidate/activeStageWithinCompanyByCandidate/',
      getCandidateNotes: '/api/candidateScore/findNoteByCandidate',
      findCognitiveAbilitiesScore:
        '/api/cognitiveAbilities/findCognitiveAbilitiesScore',
      findAllWorkExperiencesPositions: '/api/workExperience/',
      getMentalityScore: '/api/mentality',
      getTemplates:'/api/template/findAll',
      findTemplateById:'/api/template/find',

      // Post
      abortScraping: '/api/candidate/abort-scraping',
      postNewCandidate: '/api/candidate/create',
      createByVacant: '/api/candidate/createByVacant/',
      postLinkedinxVacante: '/api/candidate/crearCandidatoPorVacanteLinkedin/',
      addExperience: '/api/workExperience/save/',
      addStudy: '/api/education/save/',
      postSaveTechnologyByCandidate: '/api/technology/saveByCandidate/',
      createReport: '/api/report/create',
      uploadImg: '/api/file/uploadImg',
      uploadPdf: '/api/file/uploadPdf',
      updateStage: '/api/candidate/updateStage/',
      uploadExperienceScore: '/api/candidateScore/uploadExperienceScore',
      uploadEducationScore: '/api/candidateScore/uploadEducationScore',
      updateScoreKnowledge: '/api/technology/updateScore/',
      addNewCandidateNote: '/api/candidateScore/saveNoteByCandidate',
      getXCandidates: '/api/candidate/getXCandidates/',
      createTemplate:'/api/template/create',
      // Delete
      deleteCandidate: '/api/candidate/',
      getCandidateById: '/api/candidate/cardById/',
      getCandidateByLinkedin: '/api/candidate/getCandidatoByLinkedin/',
      deleteMentalityPerCandidate: '/api/mentality/',
      deleteFollower: '/api/candidate/deleteFollowers/',
      deleteFile: '/api/file/',
      deleteCandidateNote: '/api/candidateScore/deleteNoteByIds',
      deleteTemplate:'/api/template/delete',
      // Put
      putTechnologyUpdateScore: '/api/technology/updateScore/', // id candidato, id tecnologia, puntaje
      updateMentalityScore: '/api/mentality/mentalityScore',
      editCandidate: '/api/candidate/',
      addFollowersToCandidate: '/api/candidate/addFollower/',
      editCandidateNote: '/api/candidateScore/updateNoteByCandidate',
      cognitiveAbilitiesScore:
        '/api/cognitiveAbilities/uploadCognitiveAbilitiesScore',
        updateTemplate:'/api/template/update',
    },
    dashboard: {
      // Get
      getCompanyResponsible: '/api/listarEncargadoEmpresa/', // This URL needs the company id at the end: /api/listarEncargadoEmpresa/{idEmpresa}
      getOpportunitiesList: '/api/oportunidad/todos',
      getOpportunitiesParameters: '/api/nuevaOportunidad',
      getAllOpportunities: '/api/opportunity/findAllOpportunities',
      getAllTraineeOpportunities:
        '/api/opportunity/findAllTraineeOpportunities',
      getSeniorities: '/api/seniority/list',
      getOpportunitysCandidates: '/api/listarAsignaciones/', // This URL needs the opportunity ID as a param
      getTiposOrdenamiento: '/api/oportunidad/listarTipoOrdenamiento',
      getSortOpportunities: '/api/opportunity/sortOpportunities',
      getSortOpportunitiesTrainee: '/api/opportunity/sortOpportunitiesTrainee',
      getPrefixList: '/api/user/prefixList',
      getUniversities: '/api/university/list',
      getRecomendacionesUbicacion: '/api/locality/findByLocationName/',
      getOpportunities: '/api/opportunity/',
      getVacantes: '/api/oportunidad/getVacantesByOportunidad/',
      getVacantByOpportunity: '/api/vacant/opportunity/',
      getVacantTrainee: '/api/vacant/opportunityTrainee/',
      getdeletedCandidates: '/api/vacant/deletedCandidates/',
      getTechnologiesByVacant: '/api/vacant/findTechnologyByVacants/',
      putUpdateQuickScoreInVacant: '/api/vacant/updateQuickScoreInVacant/',
      getVacantScoreAverage: '/api/vacant/getScoreAverage/',
      getAreaOfinterest: `/api/areaOfInterest/list/`,

      // Post
      postNewOpportunity: '/api/opportunity/create',
      postNewCompany: '/api/company/createView',

      // Put
      historyDeleteCandidate: '/api/history/updateStageHistoryCandidate/',
      changeStageHistory: '/api/history/changeStageHistory/',
      putQuickScore: '/api/vacant/',
      updateOpportunityPriority: '/api/opportunity/editPriority/',
      deleteOpportunity: '/api/opportunity/deletedOpportunity/',
      deleteOpportunities: '/api/opportunity/finishOpportunities',
      edit: '/api/opportunity/',
      // Delete
    },
    representante: {
      getExistRepresentative: '/api/representative/validateExistence/',
      getEmails: '/api/ representative/emails',
      getExistEmail: '/api/representante/validateEmail/',
    },
    company: {
      getSectors: '/api/sector/',
      getExistingCuit: '/api/company/verify/', //necesita el Cuit ingresado en el form.
      getNombreExiste: '/api/company/verifyName/', //necesita el Cuit ingresado en el form.
      getProvinceList: '/api/province/list',
      getListLocalities: '/api/locality/listByIdProvince/',
      getListcategoryIVA: '/api/empresa/',
      getListNombresEmpresa: '/api/company/names',
      getListCuitsEmpresa: '/api/company/cuits',
      getAll: '/api/company/all',
      getCompanIdsAndName: '/api/company/idAndName',
    },
    opportunity: {
      getRelationshipModel: '/api/relationshipModel/list',
      getRepresentativeIdAndName: '/api/representative/idAndName/',
      getAllPrioritys: '/api/priority/list',

      getVacantById: '/api/opportunity/vacantById/',
      getActiveProcesses: '/api/opportunity/candidateAppearances/',
      deleteCandidateFromVacante: '/api/opportunity/deletedCandidate/',
    },
  },
  categoryIva: '/api/categoryIva/list',
  sizeFiles: 2 * 1024 * 1024,
  documentType: '/api/documentType/list',
};
