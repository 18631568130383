import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, Subject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Message } from 'app/models/interfaces/Message';
import { UniversityApiDto } from 'app/models/interfaces/universityApiDto.interface';
import { QuickScore } from 'app/models/interfaces/quickScore.interface';
import { CandidateDto } from 'app/models/interfaces/candidateDto.interface';
import { CompanyDto } from 'app/models/interfaces/companyDto.interface';
import { FileSaveDto } from 'app/models/interfaces/fileSaveDto.interface';
import { SectorDto } from 'app/models/interfaces/sectorDto.interface';
import { CategoryIVADto } from 'app/models/interfaces/categoryIVADto.interface';
import { ProvinceApiDto } from 'app/models/interfaces/provinceApiDto.interface';
import { DocumentTypeDto } from 'app/models/interfaces/documentTypeDto.interface';
import { LocalityApiDto } from 'app/models/interfaces/localityApiDto.interface';
import { RelationshipModel } from 'app/models/interfaces/relationshipModel.interface';
import { SeniorityDto } from 'app/models/interfaces/seniorityDto.interface';
import { LevelsDto } from 'app/models/interfaces/levelsDto.interface';
import { TechnologyDto } from 'app/models/interfaces/technologyDto.interface';
import { PriorityDto } from 'app/models/interfaces/priorityDto.interface';
import { PrefixDto } from 'app/models/interfaces/prefixDto.interface';
import { BusinessActivity } from 'app/models/interfaces/businessActivity.interface';
import { Profiling } from 'app/models/interfaces/profiling.interface';
import { CdkDragStart } from '@angular/cdk/drag-drop';
import { Language } from 'app/models/interfaces/language.interface';
import { InterestArea } from 'app/models/interfaces/createCandidate.interface';
import Swal from 'sweetalert2';

@Injectable()
export class DashboardService {
  resetPageIndexTrainee: BehaviorSubject<boolean>;
  changeDashToTrainee: Subject<boolean>;
  updateCandidateTechnology: Subject<TechnologyDto> = new Subject();
  candidates!: CandidateDto[];
  isDragging!: CdkDragStart;
  private allTechnologies:TechnologyDto[]|null = null;

  // Base URL:
  private baseUrl: string = environment.url.protocol + environment.url.url;

  // URLs:
  // Get
  private urlGetLanguageLevels: string =
    this.baseUrl + environment.services.candidates.getLanguageLevels;
  private urlGetTechnologies: string =
    this.baseUrl + environment.services.candidates.getTechnologies;
  private urlGetSectors: string =
    this.baseUrl + environment.services.company.getSectors;
  private urlGetSeniorities: string =
    this.baseUrl + environment.services.dashboard.getSeniorities;
  private urlGetExistCuit: string =
    this.baseUrl + environment.services.company.getExistingCuit;
  private urlGetProvinceList: string =
    this.baseUrl + environment.services.company.getProvinceList;
  private urlGetListLocalities: string =
    this.baseUrl + environment.services.company.getListLocalities;
  private urlGetCompanyNames: string =
    this.baseUrl + environment.services.company.getListNombresEmpresa;
  private urlGetCuitCompany: string =
    this.baseUrl + environment.services.company.getListCuitsEmpresa;
  private urlCheckCompanyName: string =
    this.baseUrl + environment.services.company.getNombreExiste;
  private urlGetRelationshipModel: string =
    this.baseUrl + environment.services.opportunity.getRelationshipModel;
  private urlGetPrioritys: string =
    this.baseUrl + environment.services.opportunity.getAllPrioritys;
  private urlGetProfiles: string =
    this.baseUrl + environment.services.candidates.getProfiles;
  private urlGetCompanIdsAndName: string =
    this.baseUrl + environment.services.company.getCompanIdsAndName;
  private urlRecomendedList: string =
    this.baseUrl + environment.services.candidates.getRecomendList;
  private urlGetPrefixList: string =
    this.baseUrl + environment.services.dashboard.getPrefixList;
  private urlGetBusinessActivities: string =
    this.baseUrl + environment.services.candidates.getBusinessActivities;
  private urlGetUniversities: string =
    this.baseUrl + environment.services.dashboard.getUniversities;
  private urlGetLanguages: string =
    this.baseUrl + environment.services.candidates.getLanguages;
  private getCategory: string = this.baseUrl + environment.categoryIva;
  private getDocumentType: string = this.baseUrl + environment.documentType;
  private getSizeFiles: number = environment.sizeFiles;
  private urlGetAreaOfInterest: string =
    this.baseUrl + environment.services.dashboard.getAreaOfinterest;
  // Post
  private urlAddCompanyPhoto: string =
    this.baseUrl + environment.services.candidates.uploadImg;
  private urlPostNewCompany: string =
    this.baseUrl + environment.services.dashboard.postNewCompany;
  // Put
  private urlDeleteHistoryCandidate: string =
    this.baseUrl + environment.services.dashboard.historyDeleteCandidate;
  private urlPutQuickScore: string =
    this.baseUrl + environment.services.dashboard.putQuickScore;
  private urlAddFollowersCandidate: string =
    this.baseUrl + environment.services.candidates.addFollowersToCandidate;
  // Delete
  private urlDeleteCandidateFromOportunity: string =
    this.baseUrl + environment.services.opportunity.deleteCandidateFromVacante;

  constructor(private httpClient: HttpClient) {
    this.changeDashToTrainee = new Subject();
    this.resetPageIndexTrainee = new BehaviorSubject<boolean>(false);
  }

  sendPhotoCompany(file: FileSaveDto) {
    const url = this.urlAddCompanyPhoto;
    return this.httpClient.post(url, file);
  }

  sendQuickScore(
    vacantID: number,
    candidateID: number,
    score: QuickScore
  ): Observable<Message> {
    const url = this.urlPutQuickScore + vacantID + '/' + candidateID;
    return this.httpClient.put<Message>(url, score);
  }

  getFilesSize(): number {
    return this.getSizeFiles;
  }

  getSectors(): Observable<SectorDto[]> {
    const url = this.urlGetSectors;
    return this.httpClient.get<SectorDto[]>(url);
  }

  cuitValidator(cuit: number): Observable<boolean> {
    const url = this.urlGetExistCuit + cuit;
    return this.httpClient.get<boolean>(url);
  }

  validatorExistName(nombre: string): Observable<boolean> {
    const url = this.urlCheckCompanyName + nombre;
    return this.httpClient.get<boolean>(url);
  }

  getCategoryIva(): Observable<CategoryIVADto[]> {
    const url = this.getCategory;
    return this.httpClient.get<CategoryIVADto[]>(url);
  }

  getTiposDoc(): Observable<DocumentTypeDto[]> {
    const url = this.getDocumentType;
    return this.httpClient.get<DocumentTypeDto[]>(url);
  }

  getProvinces(): Observable<ProvinceApiDto[]> {
    const url = this.urlGetProvinceList;
    return this.httpClient.get<ProvinceApiDto[]>(url);
  }

  getLocalitiesByProvinceID(provinceID: number): Observable<LocalityApiDto[]> {
    const url = this.urlGetListLocalities + provinceID;
    return this.httpClient.get<LocalityApiDto[]>(url);
  }

  getNombresExistentes(): Observable<string[]> {
    const url = this.urlGetCompanyNames;
    return this.httpClient.get<string[]>(url);
  }

  getExistingCuit(): Observable<string[]> {
    const url = this.urlGetCuitCompany;
    return this.httpClient.get<string[]>(url);
  }

  getCompanyIdAndName(): Observable<CompanyDto[]> {
    const url = this.urlGetCompanIdsAndName;
    return this.httpClient.get<CompanyDto[]>(url);
  }

  getRelationshipModel(): Observable<RelationshipModel[]> {
    const url = this.urlGetRelationshipModel;
    return this.httpClient.get<RelationshipModel[]>(url);
  }

  getSeniority(): Observable<SeniorityDto[]> {
    const url = this.urlGetSeniorities;
    return this.httpClient.get<SeniorityDto[]>(url);
  }

  getLanguageLevels(): Observable<LevelsDto[]> {
    const url = this.urlGetLanguageLevels;
    return this.httpClient.get<LevelsDto[]>(url).pipe(
      map((niveles: LevelsDto[]) => {
        const auxLevel: LevelsDto[] = [];
        niveles.forEach(element => {
          auxLevel.push(element);
        });
        return auxLevel;
      })
    );
  }

  getTechnologies(): Observable<TechnologyDto[]> {
    if(this.allTechnologies) return of(this.allTechnologies);
    const url = this.urlGetTechnologies;
    return this.httpClient.get<TechnologyDto[]>(url).pipe(
        tap((res)=> this.allTechnologies = res)
    );
  }

  getPriority(): Observable<PriorityDto[]> {
    const url = this.urlGetPrioritys;
    return this.httpClient.get<PriorityDto[]>(url);
  }

  getProfiles(): Observable<Profiling[]> {
    const url = this.urlGetProfiles;
    return this.httpClient.get<Profiling[]>(url);
  }

  getComparisonByIdCandidate(
    vacantID: number,
    candidateID: number
  ): Observable<CandidateDto[]> {
    const url = this.urlRecomendedList + candidateID + '/' + vacantID;
    return this.httpClient.get<CandidateDto[]>(url);
  }

  deleteCandidateFromOportunity(
    candidateID: number,
    vacantID: number
  ): Observable<Message> {
    const url =
      this.urlDeleteCandidateFromOportunity + vacantID + '/' + candidateID;
    return this.httpClient.delete<Message>(url);
  }

  historyDeleteCandidate(
    candidateID: number,
    vacantID: number,
    reason: string
  ): Observable<Message> {
    const url = this.urlDeleteHistoryCandidate + candidateID + '/' + vacantID;
    return this.httpClient.put<Message>(url, reason);
  }

  getPrefixList(): Observable<PrefixDto[]> {
    const url = this.urlGetPrefixList;
    return this.httpClient
      .get<PrefixDto[]>(url)
      .pipe(map(this._filterPrefixDtos));
  }

  private _filterPrefixDtos(prefixList: PrefixDto[]): PrefixDto[] {
    const data = prefixList.map<PrefixDto>(element => {
      element.prefix = element.prefix.split(' ').join('');
      return element;
    });
    let hash: any = {};
    return data
      .filter(element => {
        return hash[element.prefix] ? false : hash[element.prefix] === true;
      })
      .sort((a, b) => Number(a.prefix) - Number(b.prefix));
  }

  getBusinessActivities(): Observable<BusinessActivity[]> {
    const url = this.urlGetBusinessActivities;
    return this.httpClient.get<BusinessActivity[]>(url);
  }

  getUniversities(): Observable<UniversityApiDto[]> {
    const url = this.urlGetUniversities;
    return this.httpClient.get<UniversityApiDto[]>(url);
  }

  getLanguages(): Observable<Language[]> {
    const url = this.urlGetLanguages;
    return this.httpClient.get<Language[]>(url);
  }

  addFollowers(
    idCandidate: number,
    idVacant: number,
    recruitersIds: number[]
  ): Observable<any> {
    const url = this.urlAddFollowersCandidate + idCandidate + '/' + idVacant;
    return this.httpClient.put<any>(url, recruitersIds);
  }

  getInterestList(): Observable<InterestArea[]> {
    const url = this.urlGetAreaOfInterest;
    return this.httpClient.get<InterestArea[]>(url);
  }

  createNewCompanyObs(newCompany: CompanyDto): Observable<CompanyDto> {
    const url = this.urlPostNewCompany;
    return this.httpClient.post<CompanyDto>(url, newCompany).pipe(
      catchError(e => {
        Swal.fire('Error al crear nueva empresa', 'error', 'error');
        return throwError(e);
      })
    );
  }
}
