import { generalConfig } from './generalConfig';

const url = {
  protocol: 'https://',
  url: 'uat.backend.dashboard.talento.mobydigital.com',
};

export const environment = {
  url,
  ...generalConfig,
  production: true,
};
